/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, NETWORLD){
	const a = 'is-active';
	const v = 'is-visible';

	// メインビジュアル用json
	const getJSONmainvisual = (_maker) => {
		return $.getJSON(`/ajax/top/mainvisual.json${_maker}`);
	};
	// セミナー用json
	const getJSONseminar = (_maker) => {
		return $.getJSON(`/ajax/top/seminar.json${_maker}`);
	};
	// キャンペーン用json
	const getJSONcampaign = (_maker) => {
		return $.getJSON(`/ajax/top/campaign.json${_maker}`);
	};

	// 訪問ページのcookie取得→パラメータとして設定
	let maker = '';
	let cookies = document.cookie;
	let cookiesArray = cookies.split(';');
	for(var c of cookiesArray){
		var cArray = c.split('=');
		var pattern = 'networld_js_maker_cookie_';  // nemeのパターン
		if(cArray[0].match(pattern)){  // keyになる文字列がパターンにマッチしたら
			// console.log(cArray[1]);
			if(!(maker === '')) {
				maker += ('&p_maker='+cArray[1]);
			} else {
				maker = ('?p_maker='+cArray[1]);
			}
		}
	}
	console.log(maker,'maker');

	// メインビジュアル描画
	getJSONmainvisual(maker).then((data) => {
		console.log(data,'getJSONmainvisual');
		if(!data.rt_bn_top_mainvisual.length) {
			getJSONmainvisual('').then((data2) => {
				console.log(data2,'getJSONmainvisual-no-parm');
				let Html = '';
				for(let i=0; i < data2.rt_bn_top_mainvisual.length; i++) {
					Html += '<li><a href="'+data2.rt_bn_top_mainvisual[i].rt_cf_s_href.href+'" class="mod-a">'+
					'<img src="'+data2.rt_bn_top_mainvisual[i].rt_cf_s_image.src+'" alt="'+data2.rt_bn_top_mainvisual[i].rt_cf_s_image.alt+'" class="width-u-100">'+
					'</a></li>';
				}
				$('.idx-mainVis-slider').append(Html);
				$('.idx-mainVis-slider').slick({
					infinite: true,
					speed: 1000,
					autoplay: true,
					autoplaySpeed: 4000,
					// slidesToShow: 1,
					// variableWidth: true,
					// centerMode: true,
					slidesToShow: 3,
					prevArrow: '<div class="slide-arrow prev-arrow"><img src="/images/index/arrow_left.png"></div>',
					nextArrow: '<div class="slide-arrow next-arrow"><img src="/images/index/arrow_right.png"></div>',
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
								// variableWidth: false,
								// centerMode: false,
							}
						}
					]
				});
				$('.idx-mainVis-slider').removeClass('di_none');
			});
		} else {
			let Html = '';
			for(let i=0; i < data.rt_bn_top_mainvisual.length; i++) {
				Html += '<li><a href="'+data.rt_bn_top_mainvisual[i].rt_cf_s_href.href+'" class="mod-a">'+
				'<img src="'+data.rt_bn_top_mainvisual[i].rt_cf_s_image.src+'" alt="'+data.rt_bn_top_mainvisual[i].rt_cf_s_image.alt+'" class="width-u-100">'+
				'</a></li>';
			}
			$('.idx-mainVis-slider').append(Html);
			$('.idx-mainVis-slider').slick({
				infinite: true,
				speed: 1000,
				autoplay: true,
				autoplaySpeed: 4000,
				// slidesToShow: 1,
				// variableWidth: true,
				// centerMode: true,
				slidesToShow: 3,
				prevArrow: '<div class="slide-arrow prev-arrow"><img src="/images/index/arrow_left.png"></div>',
				nextArrow: '<div class="slide-arrow next-arrow"><img src="/images/index/arrow_right.png"></div>',
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							// variableWidth: false,
							// centerMode: false,
						}
					}
				]
			});
			$('.idx-mainVis-slider').removeClass('di_none');
		}
	});

	// セミナー描画
	getJSONseminar(maker).then((data) => {
		console.log(data,'getJSONseminar');
		if(!data.rt_bn_top_seminar.length) {
			getJSONseminar('').then((data2) => {
				console.log(data2,'getJSONseminar-no-parm');
				let Html = '';
				for(let i=0; i < data2.rt_bn_top_seminar.length; i++) {
					let lead = '';
					if(!data2.rt_bn_top_seminar[i].rt_cf_n_description_text_for_json) {
						lead = '';
					} else {
						lead = '<span class="di_block mb10">'+data2.rt_bn_top_seminar[i].rt_cf_n_description_text_for_json.content.replaceAll("\n", "<br>").replaceAll("\r", "<br>").replaceAll("\r\n", "<br>")+'</span>';
					}

					let makerList = '';
					for(let l=0; l < data2.rt_bn_top_seminar[i].cf_n_maker.length; l++) {
						if(!(data2.rt_bn_top_seminar[i].cf_n_maker[l].cf_m_name === null)) {
							makerList += '<li><span class="mod-label-keyword mod-label-keyword02 opacity">'+data2.rt_bn_top_seminar[i].cf_n_maker[l].cf_m_name+'</span></li>';
						}
					}

					let startEndDate = '';
					// console.log(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue1_exist);
					if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue1_exist == 'true') {
						let _text = '';
						if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue1.content === 'other') {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text1.content;
						} else {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue1.content;
						}
						if(!(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date1.content == data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date1.content)) {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">（開催日）'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date1.content+'～ '+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date1.content+'（終了日）</span>'+
							'</div>';
						} else {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date1.content+'</span>'+
							'</div>';
						}
					}
					if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue2_exist == 'true') {
						let _text = '';
						if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue2.content === 'other') {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text2.content;
						} else {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue2.content;
						}
						if(!(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date2.content == data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_data2.content)) {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">（開催日）'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date2.content+'～ '+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_data2.content+'（終了日）</span>'+
							'</div>';
						} else {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date2.content+'</span>'+
							'</div>';
						}
					}
					if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue3_exist == 'true') {
						let _text = '';
						if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue3.content === 'other') {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text3.content;
						} else {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue3.content;
						}
						if(!(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date3.content == data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date3.content)) {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">（開催日）'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date3.content+'～ '+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date3.content+'（終了日）</span>'+
							'</div>';
						} else {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date3.content+'</span>'+
							'</div>';
						}
					}
					if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue4_exist == 'true') {
						let _text = '';
						if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue4.content === 'other') {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text4.content;
						} else {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue4.content;
						}
						if(!(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date4.content == data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date4.content)) {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">（開催日）'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date4.content+'～ '+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date4.content+'（終了日）</span>'+
							'</div>';
						} else {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date4.content+'</span>'+
							'</div>';
						}
					}
					if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue5_exist == 'true') {
						let _text = '';
						if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue5.content === 'other') {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text5.content;
						} else {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue5.content;
						}
						if(!(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date5.content == data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date5.content)) {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">（開催日）'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date5.content+'～ '+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date5.content+'（終了日）</span>'+
							'</div>';
						} else {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date5.content+'</span>'+
							'</div>';
						}
					}
					if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue6_exist == 'true') {
						let _text = '';
						if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue6.content === 'other') {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text6.content;
						} else {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue6.content;
						}
						if(!(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date6.content == data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date6.content)) {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">（開催日）'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date6.content+'～ '+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date6.content+'（終了日）</span>'+
							'</div>';
						} else {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date6.content+'</span>'+
							'</div>';
						}
					}
					if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue7_exist == 'true') {
						let _text = '';
						if(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue7.content === 'other') {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text7.content;
						} else {
							_text = data2.rt_bn_top_seminar[i].rt_cf_n_seminar_venue7.content;
						}
						if(!(data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date7.content == data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date7.content)) {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">（開催日）'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date7.content+'～ '+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date7.content+'（終了日）</span>'+
							'</div>';
						} else {
							startEndDate += '<div>'+
							'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
							'<span class="fw_bold">'+data2.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date7.content+'</span>'+
							'</div>';
						}
					}
					Html += '<li class="pure-u-1-1 pure-u-lg-1-1 space-u-30-child">'+
					'<a href="'+data2.rt_bn_top_seminar[i].rt_cf_n_href.href+'" target="'+data2.rt_bn_top_seminar[i].rt_cf_n_href.target+'" class="opacity di_iblock mod-borderBottom pb30">'+
					'<span class="di_block fw_bold mb10">'+data2.rt_bn_top_seminar[i].rt_cf_n_title_for_json.content.replaceAll("\n", "<br>").replaceAll("\r", "<br>").replaceAll("\r\n", "<br>")+'</span>'+
					lead+
					startEndDate+
					'<div class="pt20"><ul class="mod-labelWrap">'+
					makerList+
					'</ul></div>'+
					'</a>'+
					'</li>';
				}
				$('.js-seminars-list').append(Html);
				$('.js-seminars-list').removeClass('di_none');
			});
		} else {
			let Html = '';
			for(let i=0; i < data.rt_bn_top_seminar.length; i++) {
				let lead = '';
				if(!data.rt_bn_top_seminar[i].rt_cf_n_description_text_for_json) {
					lead = '';
				} else {
					lead = '<span class="di_block mb10">'+data.rt_bn_top_seminar[i].rt_cf_n_description_text_for_json.content.replaceAll("\n", "<br>").replaceAll("\r", "<br>").replaceAll("\r\n", "<br>")+'</span>';
				}

				let makerList = '';
				for(let l=0; l < data.rt_bn_top_seminar[i].cf_n_maker.length; l++) {
					if(!(data.rt_bn_top_seminar[i].cf_n_maker[l].cf_m_name === null)) {
						makerList += '<li><span class="mod-label-keyword mod-label-keyword02 opacity">'+data.rt_bn_top_seminar[i].cf_n_maker[l].cf_m_name+'</span></li>';
					}
				}

				let startEndDate = '';
				// console.log(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue1_exist);
				if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue1_exist == 'true') {
					let _text = '';
					if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue1.content === 'other') {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text1.content;
					} else {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue1.content;
					}
					if(!(data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date1.content == data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date1.content)) {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">（開催日）'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date1.content+'～ '+data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date1.content+'（終了日）</span>'+
						'</div>';
					} else {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date1.content+'</span>'+
						'</div>';
					}
				}
				if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue2_exist == 'true') {
					let _text = '';
					if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue2.content === 'other') {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text2.content;
					} else {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue2.content;
					}
					if(!(data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date2.content == data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date2.content)) {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">（開催日）'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date2.content+'～ '+data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date2.content+'（終了日）</span>'+
						'</div>';
					} else {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date2.content+'</span>'+
						'</div>';
					}
				}
				if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue3_exist == 'true') {
					let _text = '';
					if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue3.content === 'other') {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text3.content;
					} else {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue3.content;
					}
					if(!(data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date3.content == data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date3.content)) {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">（開催日）'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date3.content+'～ '+data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date3.content+'（終了日）</span>'+
						'</div>';
					} else {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date3.content+'</span>'+
						'</div>';
					}
				}
				if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue4_exist == 'true') {
					let _text = '';
					if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue4.content === 'other') {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text4.content;
					} else {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue4.content;
					}
					if(!(data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date4.content == data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date4.content)) {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">（開催日）'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date4.content+'～ '+data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date4.content+'（終了日）</span>'+
						'</div>';
					} else {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date4.content+'</span>'+
						'</div>';
					}
				}
				if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue5_exist == 'true') {
					let _text = '';
					if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue5.content === 'other') {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text5.content;
					} else {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue5.content;
					}
					if(!(data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date5.content == data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date5.content)) {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">（開催日）'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date5.content+'～ '+data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date5.content+'（終了日）</span>'+
						'</div>';
					} else {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date5.content+'</span>'+
						'</div>';
					}
				}
				if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue6_exist == 'true') {
					let _text = '';
					if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue6.content === 'other') {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text6.content;
					} else {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue6.content;
					}
					if(!(data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date6.content == data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date6.content)) {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">（開催日）'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date6.content+'～ '+data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date6.content+'（終了日）</span>'+
						'</div>';
					} else {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date6.content+'</span>'+
						'</div>';
					}
				}
				if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue7_exist == 'true') {
					let _text = '';
					if(data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue7.content === 'other') {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue_text7.content;
					} else {
						_text = data.rt_bn_top_seminar[i].rt_cf_n_seminar_venue7.content;
					}
					if(!(data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date7.content == data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date7.content)) {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">（開催日）'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date7.content+'～ '+data.rt_bn_top_seminar[i].rt_cf_n_seminar_end_date7.content+'（終了日）</span>'+
						'</div>';
					} else {
						startEndDate += '<div>'+
						'<span class="mod-label mod-label01 di_iblock mr20">'+_text+'</span>'+
						'<span class="fw_bold">'+data.rt_bn_top_seminar[i].rt_cf_n_seminar_start_date7.content+'</span>'+
						'</div>';
					}
				}
				Html += '<li class="pure-u-1-1 pure-u-lg-1-1 space-u-30-child">'+
				'<a href="'+data.rt_bn_top_seminar[i].rt_cf_n_href.href+'" target="'+data.rt_bn_top_seminar[i].rt_cf_n_href.target+'" class="opacity di_iblock mod-borderBottom pb30">'+
				'<span class="di_block fw_bold mb10">'+data.rt_bn_top_seminar[i].rt_cf_n_title_for_json.content.replaceAll("\n", "<br>").replaceAll("\r", "<br>").replaceAll("\r\n", "<br>")+'</span>'+
				lead+
				startEndDate+
				'<div class="pt20"><ul class="mod-labelWrap">'+
				makerList+
				'</ul></div>'+
				'</a>'+
				'</li>';
			}
			$('.js-seminars-list').append(Html);
			$('.js-seminars-list').removeClass('di_none');
		}
	});

	// キャンペーン描画
	getJSONcampaign(maker).then((data) => {
		console.log(data,'getJSONcampaign');
		if(!data.rt_bn_top_campaign.length) {
			getJSONcampaign('').then((data2) => {
				console.log(data2,'getJSONcampaign-no-parm');
				let Html = '';
				for(let i=0; i < data2.rt_bn_top_campaign.length; i++) {
					let newIcon = '';
					if(!data2.rt_bn_top_campaign[i].rt_cf_n_icon_new) {
						newIcon = '';
					} else {
						newIcon = '<p class="mod-label-new"><img src="/common/images/label_new.png" class="" alt="new"><span class="font_jost">NEW</span></p>';
					}

					// 画像が無い場合・画像とアイコンが無い場合クラス追加
					let img = '';
					let imgif = '';
					if(!data2.rt_bn_top_campaign[i].rt_cf_n_img) {
						img = '';
						imgif = '_noimg';
						if(!newIcon) {
							imgif = '_noimg_noicon';
						}
					} else {
						img = '<span><img src="'+data2.rt_bn_top_campaign[i].rt_cf_n_img.src+'" alt="'+data2.rt_bn_top_campaign[i].rt_cf_n_img.alt+'" class="width-u-100"></span>';
					}

					let lead = '';
					if(!data2.rt_bn_top_campaign[i].rt_cf_n_description_text_for_json) {
						lead = '';
					} else {
						lead = '<span class="di_block mb10">'+data2.rt_bn_top_campaign[i].rt_cf_n_description_text_for_json.content.replaceAll("\n", "<br>").replaceAll("\r", "<br>").replaceAll("\r\n", "<br>")+'</span>';
					}

					Html += '<li class="pure-u-1-1 pure-u-lg-1-1 space-u-30-child">'+
					'<a href="'+data2.rt_bn_top_campaign[i].rt_cf_n_href.href+'" target="'+data2.rt_bn_top_campaign[i].rt_cf_n_href.target+'" class="opacity di_iblock mod-borderBottom pb30 '+imgif+'">'+
					'<div class="idx-semicam-campaign-cont-img">'+
					newIcon+
					img+
					'</div>'+
					'<div class="idx-semicam-campaign-cont-info">'+
					'<span class="di_block fw_bold mb10">'+data2.rt_bn_top_campaign[i].rt_cf_n_title_for_json.content.replaceAll("\n", "<br>").replaceAll("\r", "<br>").replaceAll("\r\n", "<br>")+'</span>'+
					lead+
					'<span class="fw_bold">'+data2.rt_bn_top_campaign[i].rt_cf_n_start_date.content+''+data2.rt_bn_top_campaign[i].rt_cf_n_end_date.content+'</span>'+
					'</div>'+
					'</a>'+
					'</li>';
				}
				$('.js-campaign-list').append(Html);
				$('.js-campaign-list').removeClass('di_none');
			});
		} else {
			let Html = '';
			for(let i=0; i < data.rt_bn_top_campaign.length; i++) {
				let newIcon = '';
				if(!data.rt_bn_top_campaign[i].rt_cf_n_icon_new) {
					newIcon = '';
				} else {
					newIcon = '<p class="mod-label-new"><img src="/common/images/label_new.png" class="" alt="new"><span class="font_jost">NEW</span></p>';
				}

				// 画像が無い場合・画像とアイコンが無い場合クラス追加
				let img = '';
				let imgif = '';
				if(!data.rt_bn_top_campaign[i].rt_cf_n_img) {
					img = '';
					imgif = '_noimg';
					if(!newIcon) {
						imgif = '_noimg_noicon';
					}
				} else {
					img = '<span><img src="'+data.rt_bn_top_campaign[i].rt_cf_n_img.src+'" alt="'+data.rt_bn_top_campaign[i].rt_cf_n_img.alt+'" class="width-u-100"></span>';
				}

				let lead = '';
				if(!data.rt_bn_top_campaign[i].rt_cf_n_description_text_for_json) {
					lead = '';
				} else {
					lead = '<span class="di_block mb10">'+data.rt_bn_top_campaign[i].rt_cf_n_description_text_for_json.content.replaceAll("\n", "<br>").replaceAll("\r", "<br>").replaceAll("\r\n", "<br>")+'</span>';
				}

				Html += '<li class="pure-u-1-1 pure-u-lg-1-1 space-u-30-child">'+
				'<a href="'+data.rt_bn_top_campaign[i].rt_cf_n_href.href+'" target="'+data.rt_bn_top_campaign[i].rt_cf_n_href.target+'" class="opacity di_iblock mod-borderBottom pb30 '+imgif+'">'+
				'<div class="idx-semicam-campaign-cont-img">'+
				newIcon+
				img+
				'</div>'+
				'<div class="idx-semicam-campaign-cont-info">'+
				'<span class="di_block fw_bold mb10">'+data.rt_bn_top_campaign[i].rt_cf_n_title_for_json.content.replaceAll("\n", "<br>").replaceAll("\r", "<br>").replaceAll("\r\n", "<br>")+'</span>'+
				lead+
				'<span class="fw_bold">'+data.rt_bn_top_campaign[i].rt_cf_n_start_date.content+''+data.rt_bn_top_campaign[i].rt_cf_n_end_date.content+'</span>'+
				'</div>'+
				'</a>'+
				'</li>';
			}
			$('.js-campaign-list').append(Html);
			$('.js-campaign-list').removeClass('di_none');
		}
	});
	if($('.idx-news-slider').length) {
		if(!$('.idx-news-slider .rt_bn_news_important_notices').length) {
			$('.idx-mainVis').addClass('_importantNoticeNone');
		}
		$('.idx-news-slider').slick({
			infinite: true,
			speed: 1000,
			fade: true,
			autoplay: true,
			autoplaySpeed: 4000,
			arrows: false,
			slidesToShow: 1,
			responsive: [
				{
					breakpoint: 769,
					settings: {
						variableWidth: false,
						centerMode: false,
					}
				}
			]
		});
	}

	$('.idx-case-slider').slick({
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		variableWidth: true,
		prevArrow: '<div class="slide-arrow prev-arrow"><img src="/images/index/arrow_left.png"></div>',
    	nextArrow: '<div class="slide-arrow next-arrow"><img src="/images/index/arrow_right.png"></div>',
		responsive: [
			{
				breakpoint: 769,
				settings: {
					variableWidth: false,
					centerMode: false,
				}
			}
		]
	});

	if($('.js-top-product').length) {
		const getJSONproduct = (_tag) => {
			return $.getJSON(`/ajax/top/maker/${_tag}.json`);
		};
		for(let i=0; i < $('.js-top-product').length; i++) {
			getJSONproduct($('.js-top-product').eq(i).prev('span').text()).then((data) => {
				// console.log(data,'data');
				let addHtml = '';
				for(let l=0; l < data.rt_bn_maker.length; l++) {
					addHtml += '<li class="pure-u-1-5 pure-u-lg-1-3 pure-u-md-1-3 mod-spaceU-child">'+
					'<a href="'+data.rt_bn_maker[l].rt_cf_m_href.href+'" class="di_block opacity">'+
					'<figure class="ta_center"><img src="'+data.rt_bn_maker[l].rt_cf_m_image1.src+'" class="width-u-100" alt="'+data.rt_bn_maker[l].rt_cf_m_image1.alt+'"></figure>'+
					'</a>'+
					'</li>';
				}
				$('.js-top-product').eq(i).append(addHtml);
			});
		}
		
	}


})(window.jQuery, window.NETWORLD);
